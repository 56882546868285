.pricingWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff5e6; /* Light beige background */
    padding: 40px 0;
}

.pricingContainer {
    width: 90%;
    max-width: 1000px;
    background-color: rgba(245, 245, 220, 0.9); /* Semi-transparent beige */
    border: 3px solid #8b4513; /* SaddleBrown border */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mainTitle {
    color: #8b4513; /* SaddleBrown text */
    margin-bottom: 30px;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.sectionTitle {
    font-size: 24px;
    color: #a0522d; /* Sienna */
    margin: 30px 0 20px 0;
    padding: 10px 0;
    border-bottom: 2px solid #8b4513; /* SaddleBrown border */
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.pricingContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    padding: 20px;
    border: 2px solid #d3d3d3;
    border-radius: 10px;
    background-color: #fff; /* White background for contrast */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.pricingImageContainer {
    flex: 1;
    text-align: left;
}

.pricingImage {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.pricingDetails {
    flex: 1;
    padding-left: 20px;
}

.priceList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.priceItem {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #d3d3d3;
}

.serviceTitle {
    font-size: 18px;
    color: #3c3c3b; /* Darker beige */
}

.servicePrice {
    font-size: 18px;
    color: #a0522d; /* Sienna */
}

.bookButton {
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 18px;
    color: #fff;
    background-color: #a0522d; /* Sienna button color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.bookButton:hover {
    background-color: #8b4513; /* Darker Sienna on hover */
}

/* Add this to increase the spacing between sections */
.sectionSpacing {
    margin-bottom: 40px;
}

/* Media Queries for Responsive Design */
@media only screen and (max-width: 650px) {
    .pricingContent {
        flex-direction: column;
        align-items: center;
    }

    .pricingDetails {
        padding-left: 0;
        margin-top: 20px;
    }

    .priceItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .serviceTitle,
    .servicePrice {
        font-size: 16px;
    }

    .bookButton {
        padding: 10px 20px;
        font-size: 16px;
    }
}
